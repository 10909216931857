const numFormat = function (num) {
  const NUM = Number(num)
  const lang = useCookie('i18n_redirected')
  if (lang.value === 'en') {
    if (NUM > 10000000) {
      return (NUM / 1000000).toFixed(3) + 'M'
    }
    if (NUM > 10000) {
      return (NUM / 1000).toFixed(3) + 'K'
    }
    return NUM
  } else {
    if (NUM > 100000000) {
      return (NUM / 100000000).toFixed(3) + '亿'
    }
    if (NUM > 10000) {
      return (NUM / 10000).toFixed(3) + '万'
    }
    return NUM
  }
}

const formatSortVal = function (val, defaultVal) {
  let sort = ''

  if (defaultVal && defaultVal === 'DESC') {
    switch (val) {
      case 'DESC':
        sort = 'ASC'
        break
      case 'ASC':
        sort = ''
        break
      default:
        sort = 'DESC'
        break
    }
  } else {
    switch (val) {
      case 'ASC':
        sort = 'DESC'
        break
      case 'DESC':
        sort = ''
        break
      default:
        sort = 'ASC'
        break
    }
  }

  return sort
}

// 处理字典属性
const handleTagMap = function (data, commonDictionary, type) {
  const curType = type || ''

  if (curType) {
    if (data[curType]?.tags && data[curType]?.tags[0]) {
      data[curType]?.tags.forEach(item => {
        const field = item.split('#')[0]
        const value = item.split('#')[1]

        data[field.toLocaleLowerCase()] = Object.assign({}, commonDictionary?.csgoItemAttrMap[field].values[value], {
          internalName: value
        })
      })
    }
  } else {
    if (data?.tags && data?.tags[0]) {
      data?.tags.forEach(item => {
        const field = item.split('#')[0]
        const value = item.split('#')[1]

        data[field.toLocaleLowerCase()] = Object.assign({}, commonDictionary?.csgoItemAttrMap[field].values[value], {
          internalName: value
        })
      })
    }
  }

  return data
}

const suspensionColor = function (val) {
  if (val * 1 < 0.75) {
    return '#8F0012'
  } else if (val * 1 <= 0.85) {
    return '#FF0020'
  } else {
    return '#0DAB62'
  }
}

const add = (num1, num2) => {
  const e = Math.pow(10, Math.max(getDecimalLength(num1), getDecimalLength(num2)))

  return (mul(num1, e) + mul(num2, e)) / e
}

// 浮点数处理
const getDecimalLength = num => {
  try {
    return num.toString().split('.')[1].length
  } catch (e) {
    return 0
  }
}

// 乘
const mul = (num1, num2) => {
  let c = 0,
    d = num1.toString(),
    e = num2.toString()
  try {
    c += getDecimalLength(num1)
  } catch (err) { }

  try {
    c += getDecimalLength(num2)
  } catch (err) { }

  return (Number(d.replace('.', '')) * Number(e.replace('.', ''))) / Math.pow(10, c)
}

const hexToRgba = (hex, alpha) => {
  let r = 0,
    g = 0,
    b = 0
  // 3 digits
  if (hex.length == 4) {
    r = parseInt(hex[1] + hex[1], 16)
    g = parseInt(hex[2] + hex[2], 16)
    b = parseInt(hex[3] + hex[3], 16)
  }
  // 6 digits
  else if (hex.length == 7) {
    r = parseInt(hex[1] + hex[2], 16)
    g = parseInt(hex[3] + hex[4], 16)
    b = parseInt(hex[5] + hex[6], 16)
  }
  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

export { numFormat, formatSortVal, handleTagMap, suspensionColor, add, hexToRgba }
